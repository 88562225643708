import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import { UserAuth } from 'src/models/userAuth';
import axios from 'src/utils/axios';
import PropTypes from 'prop-types';
import { firebaseAppQP } from 'src/utils/firebaseQP';
import { usersByEmailOrDU } from 'src/slices/auth/authAction';
import { useDispatch } from 'src/store';
import { SnackbarUtils} from "src/utils/sncakbarUtilsConfigurator";
import { useTranslation } from 'react-i18next';
import { FIREBASE_ERRORS } from 'src/utils/constants';
import { useNavigate } from 'react-router-dom';
import axiosInst from 'src/utils/axiosCreate';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: UserAuth | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: any | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: any;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: UserAuth;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (accessToken: string | null, email?: string): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('userEmail', email);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userEmail');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const reduxDispatch = useDispatch();
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();


  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const email = window.localStorage.getItem('userEmail');
        if (accessToken) {
          setSession(accessToken, email);
          // valido que este el usuario..
          const userByDnuOrEmail = await reduxDispatch(
            usersByEmailOrDU(email, accessToken)
          );

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: userByDnuOrEmail.data.users[0]
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    axiosInst.interceptors.response.use(
      response => response,
      async (error) => {
        if (error.response?.status === 401) {
          await logout();
          navigate('/account/login');
        }
        return Promise.reject(error);
      }
    );
  }, [navigate]);

  const login = async (email: string, password: string): Promise<void> => {
    try {
      const loginFirebase = await firebaseAppQP
        .auth()
        .signInWithEmailAndPassword(email, password);
      
      if (!!loginFirebase && !loginFirebase.user && !!loginFirebase.user.email) {
        return SnackbarUtils.error(t('Login failed'));
      }
      
      const token = await loginFirebase.user.getIdToken();
      setSession(token, email);
      const userByDnuOrEmail = await reduxDispatch(usersByEmailOrDU(email));

      if (!userByDnuOrEmail.data.users[0].proRoles && userByDnuOrEmail.data.users[0].proRoles === undefined) {
        return SnackbarUtils.error(t('You do not have permissions to enter the app'));
      }
      
      if (
        !userByDnuOrEmail ||
        !userByDnuOrEmail.data ||
        !userByDnuOrEmail.data.users ||
        !userByDnuOrEmail.data.users[0]
      ) {
        return SnackbarUtils.error(t('Login failed'));
      }
      // END COMMENT

      dispatch({
        type: 'LOGIN',
        payload: {
          user: loginFirebase
        }
      });
      navigate("/dashboard/management/events/list");
    } catch (e) {
      let errorMessage = t('Login failed');
      if (e && e.code) {
        switch (e.code) {
          case FIREBASE_ERRORS.INVALID_EMAIL:
            errorMessage = t("The email address is not valid. Please check and try again.");
            break;
          case FIREBASE_ERRORS.USER_DISABLED:
            errorMessage = t("This user account has been disabled. Please contact support.");
            break;
          case FIREBASE_ERRORS.USER_NOT_FOUND:
            errorMessage = t("No account found with this email. Please register first.");
            break;
          case FIREBASE_ERRORS.WRONG_PASSWORD:
            errorMessage = t("Incorrect password. Please try again.");
            break;
          default:
            errorMessage = t('Login failed');
        }
      }
      return SnackbarUtils.error(errorMessage);
    }
  };

  const logout = async (): Promise<void> => {
    try {
      await firebaseAppQP.auth().signOut();
    } catch (err) {
      console.error('Firebase logout error:', err);
    }  
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const response = await axios.post<{ accessToken: string; user: UserAuth }>(
      '/api/account/register',
      {
        email,
        name,
        password
      }
    );
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired as PropTypes.Validator<ReactNode>
};

export default AuthContext;
