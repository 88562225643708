import { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import Login from 'src/content/pages/Auth/Login';
import {firebaseAppQP} from 'src/utils/firebaseQP';

interface AuthenticatedProps {
  children: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const userQP = firebaseAppQP.auth().currentUser;
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  useEffect(() => {
    console.log('Authenticated component mounted');
    return () => {
      console.log('Authenticated component unmounted');
    };
  }, []);

  if (!auth.isAuthenticated) {
    console.log('User not authenticated, redirecting to login');
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Navigate to="/account/login" />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    // console.log('Redirecting to requested location:', requestedLocation);
    setRequestedLocation(null);
    return <Navigate to={"/dashboard/management/events/list"} replace/>;
  }

  if (!userQP) {
    console.log(' No userQP in firebase auth');
    return <Navigate to="/account/login" />;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to="/account/login" />;
  }

  // console.log('Rendering children components');
  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node as PropTypes.Validator<ReactNode>
};

export default Authenticated;
