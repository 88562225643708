export const THUMBNAIL_PLACEHOLDER: string =
  'https://firebasestorage.googleapis.com/v0/b/faro-dev-d2099.appspot.com/o/images%2Fthumbnail%2Fthumbnail-placeholder.png?alt=media&token=97df4694-81fd-4661-8657-944d3ebb0a3e';
export const FULL_SIZE_PLACEHOLDER: string =
  'https://firebasestorage.googleapis.com/v0/b/faro-dev-d2099.appspot.com/o/images%2Fimages_full_size%2Fimage-full-size-placeholder.png?alt=media&token=0f4f2d7b-e052-48e2-bfa3-4bf5f71725dc';
export const FILE_ICON_PLACEHOLDER: string =
  'https://firebasestorage.googleapis.com/v0/b/faro-dev-d2099.appspot.com/o/documents%2Ffile-icon.png?alt=media&token=eb3aac6f-5acb-4ae0-ad0e-7ea94562c6ea';
export const EXCEL_TEMPLATE: string =
  'https://firebasestorage.googleapis.com/v0/b/quick-pass-prod.appspot.com/o/template%2FTemplateCargaMasiva.xlsx?alt=media&token=0685e2d2-b42c-4d92-94c2-30cbfd806d55';

// Roles en el origen de venta para tickets y estadisticas
export const TICKET_ROL_ORIGIN = {
  PRODUCER: 'producer',
  PRODUCER_BO: 'producer-bo',
  RRPPSELL: 'rrppsell',
  GIFT_BO: 'gift-bo',
  LOYALY: 'loyaly',
  STANDAR: 'standar'
};

export const COLORS_CTG = Object.freeze({
  PRODUCER: { initial: 'D', color: 'rgb(0, 0, 0)' },
  PRODUCER_BO: { initial: 'D', color: 'rgb(0, 0, 0)' },
  RRPPSELL: { initial: 'D', color: 'rgb(0, 0, 0)' },
  GIFT_BO: { initial: 'I', color: 'rgb(255, 165, 0)' },
  LOYALTY: { initial: 'C', color: 'rgb(176, 168, 13)' },
  STANDAR: null // No muestra inicial-avatar
});

// ERRORES DE FIREASE
export const FIREBASE_ERRORS = {
  INVALID_EMAIL: 'auth/invalid-email',
  USER_DISABLED: 'auth/user-disabled',
  USER_NOT_FOUND: 'auth/user-not-found',
  WRONG_PASSWORD: 'auth/wrong-password'
};

export const PERMISSIONS_ROL_RRPP = [
  {
    description: 'Líder de RRPP',
    key: 'proRrrpLeader'
  },
  {
    description: 'Eliminar tickets RRPP',
    key: 'rrppTicketDelete'
  }
];
